<template>
  <div id="app">
    <MenuLayout />
    <div class="main-content">
      <StatusbarLayout />
      <div class="content">
        <div class="title">
          <h2>{{ $t("public.card_details") }}</h2>
          <a href="#" class="link-intro">{{
            $t("public.view_introduction")
          }}</a>
        </div>
        <div class="card-list">
          <!-- <div class="card-item">
            <div class="title">
              <p class="card-bin">
                <label class="name">BIN</label>
                <label class="number">1000</label>
              </p>
              <img class="card-logo" src="@/assets/icons/visa.png" alt="Visa" />
            </div>
            <p class="status">{{ $t("public.normal_use") }}</p>
          </div>
          <div class="card-item">
            <div class="title">
              <p class="card-bin">
                <label class="name">BIN</label>
                <label class="number">1001</label>
              </p>
              <img class="card-logo" src="@/assets/icons/visa.png" alt="Visa" />
            </div>
            <p class="status">{{ $t("public.normal_use") }}</p>
          </div>
          <div class="card-item">
            <div class="title">
              <p class="card-bin">
                <label class="name">BIN</label>
                <label class="number">1002</label>
              </p>
              <img
                class="card-logo"
                src="@/assets/icons/mastercard_logo.png"
                alt="MasterCard"
              />
            </div>
            <p class="status">{{ $t("public.normal_use") }}</p>
          </div> -->
          <div class="card-item" v-for="(v, i) in cards" :key="i">
            <div class="title">
              <p class="card-bin">
                <label class="name">BIN</label>
                <label class="number">{{ v.number }}</label>
              </p>
              <img
                class="card-logo"
                src="@/assets/icons/mastercard_logo.png"
                alt="MasterCard"
              />
            </div>
            <p class="status" v-if="v.status == 1">
              {{ $t("public.normal_use") }}
            </p>
            <p class="status-frozen" v-else-if="v.status == 2">
              {{ $t("public.frozen") }}
            </p>
            <p class="status-unlicensed" v-else>
              {{ $t("public.unlicensed") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuLayout from "@/components/layouts/MenuLayout.vue";
import StatusbarLayout from "@/components/layouts/StatusbarLayout.vue";
import { ref, onMounted } from "vue";

const cards = ref([]);

// 获取三张卡片
const getCardList = () => {
  const data = {
    code: 0,
    data: {
      items: [
        {
          card_uid: "79a4e187f52a48b0b8a5987491c04398",
          create_time: "2024-08-30 23:52:50",
          email: "abc@qq.com",
          first_name: "a",
          last_name: "b",
          number: 1234,
          phone: "1234567890",
          phone_code: "88",
          prod_id: 1001,
          remark: "hi",
          status: 0,
        },
        {
          card_uid: "0e7221c7d4bb40e2bb8dab72b491e4ad",
          create_time: "2024-08-31 00:12:45",
          email: "abc@qq.com",
          first_name: "a",
          last_name: "b",
          number: 2345,
          phone: "1234567890",
          phone_code: " 88",
          prod_id: 1001,
          remark: "hi",
          status: 1,
        },
        {
          card_uid: "5e58171586a24cd5b9776c9b29370049",
          create_time: "2024-08-31 00:23:03",
          email: "abc@qq.com",
          first_name: "a",
          last_name: "b",
          number: 3456,
          phone: "1234567890",
          phone_code: " 88",
          prod_id: 1001,
          remark: "hi",
          status: 2,
        },
      ],
      page: 1,
      per_page: 4,
      total_count: 4,
      total_pages: 1,
    },
    message: "ok",
  };
  cards.value = data.data.items;
};
onMounted(() => {
  getCardList();
});
</script>

<style scoped>
#app {
  display: flex;
  background-color: #f7fafa;
  height: 100vh;
  font-family: Arial, sans-serif;
}

span {
  font-size: 1rem;
}

.main-content {
  width: 82%;
  background-color: #f7fafa;
}

.content {
  padding: 30px;
  background: #fff;
  margin: 8px;
  /* border-radius: 10px; */
}

.content .title {
  display: flex;
  align-items: baseline;
}

.content a {
  margin-left: 20px;
}

.card-list {
  display: flex;
  gap: 30px;
}

.card-item {
  background-color: #f7fafa;
  padding: 0 20px 0 20px;
  border-radius: 10px;
  flex: 1;
  text-align: center;
}

.card-item .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-bin {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.card-bin .name {
  color: #035dff;
}

.card-bin .number {
  margin-left: 5px;
}

.card-logo {
  width: 48px;
  height: auto;
  margin: 20px 0;
}

.status,
.status-frozen,
.status-unlicensed {
  font-size: 1rem;
  text-align: left;
}

.status {
  color: #17d000;
}
.status-frozen {
  color: #ff0000;
}

.link-intro {
  color: #007bff;
  text-decoration: none;
}

.link-intro:hover {
  text-decoration: underline;
}
</style>
