// 时间戳转 2024-09-05
function formatDate(timestamp) {
  if (typeof timestamp === "string") {
    timestamp = parseInt(timestamp, 10);
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
// 时间戳转 2024-09-05 11：39:00
function formatTimestamp(timestamp) {
  if (typeof timestamp === "string") {
    timestamp = parseInt(timestamp, 10);
  }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
// 2024-06-26T02:50:46.166Z转 2024-09-05 11：39:00
function formatDateString(dateString) {
  if (typeof dateString === "string") {
    dateString = parseInt(dateString, 10);
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export { formatDate, formatTimestamp, formatDateString };
