import * as XLSX from "xlsx";

/*
  * @description: 导出 Excel 文件
  * @param {Object} json 服务端发过来的数据
  * @param {String} name 导出的 Excel 文件名
  * @param {Array} titleArr 自定义的 Excel 表头
  * @param {String} sheetName 导出的 sheet 名字
  * @return: 无返回值，直接生成文件
*/
const exportExcel = (json, name, titleArr, sheetName) => {
  const data = [];
  const keyArray = [];

  // 获取对象中字段的数量
  const getLength = (obj) => Object.keys(obj).length;

  // 处理数据，将不需要的字段过滤掉（例如 ID）
  json.forEach((element) => {
    const rowDataArray = [];

    Object.entries(element).forEach(([key, value]) => {
      if (key !== "ID") {  // 排除 ID 字段
        // 处理数组类型的数据，将数组转为字符串（也可根据需求拆分成多列）
        if (Array.isArray(value)) {
          rowDataArray.push(JSON.stringify(value)); 
        } else {
          rowDataArray.push(value);
        }
        // 仅在第一次遍历时构建 keyArray
        if (keyArray.length < getLength(element) && !keyArray.includes(key)) {
          keyArray.push(key);
        }
      }
    });

    data.push(rowDataArray);
  });

  // 在 data 中添加自定义表头和字段表头
  data.splice(0, 0, keyArray, titleArr);

  console.log("data", data);

  // 将数据转换为 Excel 工作表
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();

  // 隐藏英文字段表头
  ws["!rows"] = [{ hidden: true }]; 

  // 添加 sheet 并导出
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${name}.xlsx`);
};

export { exportExcel };
