<template>
  <div class="main-content">
    <el-row>
      <el-col>
        <el-result icon="error" :title="$t(title)">
          <template #extra>
            <el-button type="primary" @click="goBack">{{
              $t("public.back")
            }}</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import router from "@/router";
import { onMounted, ref } from "vue";
import {useRoute } from 'vue-router'

const title = ref("public.system_is_busy");
const goBack = () => {
  router.go(-1);
};

const route = useRoute()
onMounted(() => {
  title.value = route.query.title;
});
</script>

<style scoped>
.main-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
