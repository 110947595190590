import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../pages/main/home/HomePage.vue";
import ProductPage from "../pages/main/product/ProductPage.vue";
import CardPage from "../pages/main/card/CardPage.vue";
import StatementOfAccountPage from "../pages/main/statementOfAccount/StatementOfAccountPage.vue";
import LoginPage from "../pages/login/LoginPage.vue";
import ForgetPage from "../pages/login/ForgetPage.vue";
import ErrorPage from "../pages/error/ErrorTip.vue";
// import DefaultLayout from "@/components/layouts/Defaults.vue";

const routes = [
  // {
  //   path: "/",
  //   component: DefaultLayout,
  //   children: [
  //     {
  //       path: "/card",
  //       name: "Card",
  //       component: CardPage,
  //     },
  //   ],
  // },
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/product",
    name: "Product",
    component: ProductPage,
  },
  {
    path: "/card",
    name: "Card",
    component: CardPage,
  },
  {
    path: "/statementOfAccount",
    name: "StatementOfAccount",
    component: StatementOfAccountPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/forget",
    name: "Forget",
    component: ForgetPage,
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
  },
  {
    path: "/:code?",
    name: "Product",
    component: ProductPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "Login" || to.name === "Forget" || localStorage.getItem("jwt_token")) {
    next();
  } else {
    next("Login");
  }
});

export default router;
