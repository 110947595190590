import i18n from "@/utils/lang";
import { defineStore } from "pinia";

const useInfoStore = defineStore("info", {
  // defineStore('userInfo',{})  userInfo就是这个仓库的名称name
  state: () => ({
    locale: localStorage.getItem("lang") || "zh",
    telegram: {
      initDataUnsafe: {
        query_id: "",
        user: {
          id: "",
          first_name: "",
          last_name: "",
        },
        auth_date: "",
        hash: "",
      },
    },
  }),
  getters: {},
  actions: {
    set_locale(e) {
      this.locale = e;
      localStorage.setItem("lang", e);
      i18n.global.locale.value = e;
    },
    changeLocale() {
      if (this.locale == "zh") {
        this.locale = "en";
        localStorage.setItem("lang", "en");
        i18n.global.locale.value = "en";
      } else {
        this.locale = "zh";
        localStorage.setItem("lang", "zh");
        i18n.global.locale.value = "zh";
      }
      // location.reload()
    },
    set_token(e) {
      localStorage.setItem("jwt_token", e);
    },
    remove_token() {
      localStorage.removeItem("jwt_token");
    },
  },
  persist: true,
});

export default useInfoStore;
