<template>
  <div id="app">
    <MenuLayout />
    <div class="main-content">
      <StatusbarLayout />
      <div class="content">
        <div class="title">
          <h2>{{ $t("public.product_info") }}</h2>
          <a href="#" class="link-intro" @click.prevent="goTo('/product')">{{
            $t("public.view_introduction")
          }}</a>
        </div>
        <div class="card-list">
          <div class="card-item" v-for="(v, i) in productList" :key="i">
            <div class="title">
              <span class="card-bin">
                <label class="name">{{ v.name }}</label>
              </span>
            </div>
            <div class="title">
              <span class="card-bin-2">
                <label>{{$t('public.product')}}ID：{{ v.id }}</label>
              </span>
            </div>
            <p class="status">{{ $t("public.normal_use") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuLayout from "@/components/layouts/MenuLayout.vue";
import StatusbarLayout from "@/components/layouts/StatusbarLayout.vue";
import apiClient from "@/services/api";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const goTo = (path) => {
  router.push(path);
};
const productList = ref([]);
const getProductList = async () => {
  const data = await apiClient.post("/prod/list", { page: 1, per_page: 99 });
  if (data.code == 0) {
    productList.value = data.data.items;
  }
};
onMounted(() => {
  getProductList();
});
</script>

<style scoped>
#app {
  display: flex;
  background-color: #f7fafa;
  height: 100vh;
  font-family: Arial, sans-serif;
}

span {
  font-size: 1rem;
}

.main-content {
  width: 82%;
  background-color: #f7fafa;
}

.content {
  padding: 30px;
  background: #fff;
  margin: 8px;
  /* border-radius: 10px; */
}

.content .title {
  display: flex;
  align-items: baseline;
}

.content a {
  margin-left: 20px;
}

.card-list {
  display: flex;
  gap: 30px;
}

.card-item {
  background-color: #f7fafa;
  padding: 0 20px 0 20px;
  border-radius: 10px;
  flex: 1;
}

.card-item .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-bin {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 15px;
}
.card-bin-2 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px;
}

.card-bin .name {
  color: #035dff;
}

.card-logo {
  width: 48px;
  height: auto;
  margin: 20px 0;
}

.status {
  color: #17d000;
  font-size: 1rem;
  text-align: left;
}

.link-intro {
  color: #007bff;
  text-decoration: none;
}

.link-intro:hover {
  text-decoration: underline;
}
</style>
