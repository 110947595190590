/**
 * UNKNOWN     = -1 # 未知 
INACTIVE    = 0  # 未开卡
ACTIVE      = 1  # 正常 
FROZEN      = 2  # 冻结
DELETED     = 3  # 删除 
FROZEN_SYS  = 4  # 风控冻结 
RISKY       = 5  # 高风险 
 */
export const CardStatus = {
  UNKNOWN: {
    value: -1,
    label: "public.card_status_new.UNKNOWN",
    type: "info",
    effect: "dark",
  },
  INACTIVE: {
    value: 0,
    label: "public.card_status_new.INACTIVE",
    type: "info",
    effect: "light",
  },
  ACTIVE: {
    value: 1,
    label: "public.card_status_new.ACTIVE",
    type: "primary",
    effect: "dark",
  },
  FROZE: {
    value: 2,
    label: "public.card_status_new.FROZEN",
    type: "warning",
    effect: "dark",
  },
  DELETED: {
    value: 3,
    label: "public.card_status_new.DELETED",
    type: "danger",
    effect: "dark",
  },
  FROZEN_SYS: {
    value: 4,
    label: "public.card_status_new.FROZEN_SYS",
    type: "info",
    effect: "plain",
  },
  RISKY: {
    value: 5,
    label: "public.card_status_new.RISKY",
    type: "info",
    effect: "light",
  },
};
