// src/services/api.js
import axios from "axios";
import { API_ROOT } from "../config";
import router from "../router"; // Make sure to import the router to handle navigation
import { jwtDecode } from "jwt-decode";
import { ElMessage } from "element-plus";
import { KJUR, hextob64u } from "jsrsasign";
import { ElLoading } from "element-plus";
import i18n from "@/utils/lang";

let loadingInstance;
const JWT_SECRET_KEY = process.env.VUE_APP_JWT_SECRET_KEY;
const JWT_SECRET = JSON.parse(process.env.VUE_APP_JWT_SECRET);

const apiClient = axios.create({
  // baseURL: API_ROOT,
  baseURL: "./io",
  headers: {
    // "Content-Type": "application/json",
    "Content-Type": "text/plain", // jwt 生成参数需要的请求头
  },
  timeout: 30000, // 30秒请求超时
});
// console.log(process.env.NODE_ENV ); // development production

// Request Interceptor to attach JWT token
apiClient.interceptors.request.use(
  (config) => {
    // 开启加载动画
    loadingInstance = ElLoading.service();

    const token = localStorage.getItem("jwt_token");

    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWJjQHFxLmNvbSIsImFjdGlvbiI6ImxvZ2luIn0.Y8Rkfyovo-Lf0YvR7M6UvNIMEVnHp9lj--IQ2iFfQys

    // JWT_SECRET_KEY = "HX&bYgx3dgyIX5!";
    // obj = { user: "abc@qq.com", action: "login" };
    // jwt.encode(obj, JWT_SECRET_KEY, (algorithm = "HS256"));

    // const decodedToken = jwtDecode(
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWJjQHFxLmNvbSIsImFjdGlvbiI6ImxvZ2luIn0.Y8Rkfyovo-Lf0YvR7M6UvNIMEVnHp9lj--IQ2iFfQys"
    // );
    // console.log("解析后decodedToken:", decodedToken);

    // Assuming you're storing the JWT token in localStorage
    if (token) {
      // 解析
      // token = jwtDecode(token);
      config.headers.Authorization = `Bearer ${token}`;
    }

    // 处理请求体
    if (JWT_SECRET && config.data) {
      // console.log("处理请求体", config.data);
      // 秘密密钥（在实际应用中请妥善保管，不要暴露在客户端代码中）
      // JWT 的头部
      const header = {
        alg: "HS256",
        typ: "JWT",
      };
      // 生成 JWT
      config.data = KJUR.jws.JWS.sign(
        null,
        header,
        config.data,
        JWT_SECRET_KEY
      );
      // console.log("生成 JWT:", config.data);
      // console.log("解析 JWT:", jwtDecode(config.data));
    }

    // console.log("config:", config);
    return config;
  },
  (error) => {
    // 请求错误时关闭加载动画
    if (loadingInstance) loadingInstance.close();
    return Promise.reject(error);
  }
);

// Response Interceptor to handle 401 errors
apiClient.interceptors.response.use(
  (response) => {
    if (JWT_SECRET) {
      response = jwtDecode(response.data);
      // console.log("response:", response);
      // 关闭加载动画
      if (loadingInstance) loadingInstance.close();
      return response;
    } else {
      // 关闭加载动画
      if (loadingInstance) loadingInstance.close();
      return response.data; // 不jwt
    }
  },
  (error) => {
    if (error.code === "ECONNABORTED") {
      ElMessage.error(i18n.global.t("public.time_out"));
    }
    if (error.response && error.response.status === 401) {
      // Clear the token and redirect to the login page
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("jwt_userInfo");
      router.push({ name: "Login" }); // Adjust the route name according to your project
    } else if (error.response && error.response.status === 403) {
      router.push({ name: "Error", query: { title: "public.page_not_found" } });
    }
    console.log("error:", error);
    // 关闭加载动画
    if (loadingInstance) loadingInstance.close();
    return Promise.reject(error);
  }
);

export default apiClient;
