module.exports = {
  language: {
    name: "中文",
  },
  login: {
    login: "登录",
    username: "账号",
    username_placeholder: "请输入邮箱",
    password: "密码",
    password_placeholder: "请输入密码",
    captcha: "验证码",
    captcha_placeholder: "请输入验证码",
    dispatch: "发送",
    remember_password: "记住密码",
    forgotten_password: "忘记密码",
    log_in: "登录",
    pact_label: "登录即视为已阅读并同意",
    service_agreement: "服务协议",
    and: "和",
    privacy_policy: "隐私协议",
    consent_agreement_reminder: "请同意服务协议和隐私协议",
    login_fail_reminder: "登录失败，请检查您的账号信息",
    enter_account_number_prompt: "请输入账号以接收验证码",
    captcha_sending: "验证码已发送，请检查您的邮箱",
    captcha_sending_fail: "发送验证码失败，请重试",
    captcha_input_error: "验证码输入错误",
    register: "注册",
    logout: "退出登录",
    new_password: "新密码",
    new_password_fill: "请输入新密码",
    submit: "提交",
    go_to_login: "前往登录",
  },
  public: {
    home: "首页",
    input_placeholder: "请输入",
    select_placeholder: "请选择",
    product_info: "产品信息",
    card_manage: "卡片管理",
    financial_statements: "财务对账单",
    card_details: "卡片详情",
    view_introduction: "查看介绍",
    being_used: "正在使用",
    normal_use: "正常使用",
    card_list: "卡片列表",
    wildcards: "新增卡",
    add: "新增",
    export: "导出",
    batch: "批量",
    all: "所有",
    in_use: "使用中",
    other_statuses: "其他状态",
    last_four: "卡号后四位",
    addr_last_four: "充值地址后四位",
    all_segments: "所有卡段",
    all_tags: "所有标签",
    cardholder_name: "持卡人姓名",
    all_card_types: "所有卡类型",
    select_date_range: "选择日期范围",
    start_date: "开始日期",
    end_date: "结束日期",
    card_number: "卡号",
    cardholder: "持卡人",
    refresh: "刷新",
    expiry_date: "有效期",
    security_code: "安全码",
    card_status: "卡状态",
    frozen: "冻结中",
    unlicensed: "未开卡",
    accumulated_spending: "累计消费",
    available_balance: "可用余额",
    operation: "操作",
    recharge: "充值",
    transfer: "转出",
    details: "详情",
    apply_for_physical_card: "申请实体卡",
    order_number: "订单号",
    status: "状态",
    successful: "成功",
    failed: "失败",
    time: "时间",
    type: "类型",
    amount: "金额",
    view_order: "查看订单",
    chargeback: "扣费",
    confirm: "确定",
    cancel: "取消",
    product: "产品",
    prod_reminder: "请选择产品",
    last_name: "姓",
    last_name_reminder: "请输入你的姓",
    first_name: "名",
    first_name_reminder: "请输入你的名",
    country_code: "国家区号",
    country_code_reminder: "请输入国家区号",
    phone: "手机号",
    phone_reminder: "请输入手机号",
    email: "邮箱",
    email_reminder: "请输入邮箱",
    remark: "备注",
    remark_reminder: "请输入备注",
    successful_card_opening: "开卡成功",
    system_is_busy: "系统繁忙，请稍后再试",
    breakdown_of_income_and_expenditure: "收支明细",
    chargebacks: "扣费",
    copy: "复制",
    copied: "已复制",
    send_one_link: "发送一次性链接",
    freeze: "冻结",
    freeze_successful: "冻结成功",
    unfreeze: "解冻",
    successful_defrosting: "解冻成功",
    limit_transfer: "额度转移",
    check_consumption_records: "消费记录",
    check_recharge_records: "充值记录",
    page_not_found: "页面未找到",
    back: "返回",
    sent_successfully: "发送成功",
    transfer_card_id: "转移卡ID",
    target_card_id: "目标卡ID",
    transfer_card_id_reminder: "请输入转移卡ID",
    transfer_amount_reminder: "请输入转移额度",
    transfer_target_card_id_reminder: "请输入目标卡ID",
    line_transfer_successful: "额度转移成功",
    it_must_be_a_number: "必须是数字",
    time_reminder: "请选择时间区间",
    successful_query: "查询成功",
    currency: "货币",
    fee: "费用",
    no_data: "暂无数据",
    create_time: "创建时间",
    recharge_address: "充值地址",
    chain: "区块链",
    addr: "地址",
    selection_least_one_reminder: "请先选择至少一条数据",
    cell_title: {
      merchant: "商户",
      biz_id: "充值ID",
      card_uid: "卡片ID",
      uid: "卡商卡片ID",
      number_ending: "卡尾号",
      biz_time: "充值时间",
      recharge_amount: "充值金额",
      recharge_cost: "归集成本",
      card_opening_cost: "开卡成本",
      card_opening_income: "开卡收入",
      topup_amount: "充卡金额",
      topup_cost: "充卡成本",
      topup_income: "充卡收入",
    },
    user_list: "用户列表",
    merchant: "商户",
    merchant_manage: "商户管理",
    user_added_successfully: "用户添加成功",
    submitted_successfully: "提交成功",
    modified_successfully: "修改成功",
    deleted_successfully: "删除成功",
    deleted_reminder: "确定删除吗？",
    warning: "警告",
    card_status_new: {
      UNKNOWN: "未知",
      INACTIVE: "未开卡",
      ACTIVE: "正常",
      FROZEN: "冻结",
      DELETED: "删除",
      FROZEN_SYS: "风控冻结",
      RISKY: "高风险",
    },
    view: "查看",
    balance: "余额",
    timeframe: "时间范围",
    time_frame: {
      this_week: "本周",
      this_month: "本月",
      last_week: "上周",
      last_month: "上个月",
      last7Days: "最近7天",
      last30Days: "最近30天",
      allTime: "所有日期",
      customized: "自定义",
    },
    time_out:"请求超时，请稍后再试",
  },
};
