<template>
  <div id="card-page">
    <MenuLayout />
    <div class="main-content">
      <StatusbarLayout />
      <div class="content-container">
        <div class="content">
          <div class="title">
            <h2>{{ $t("public.financial_statements") }}</h2>
            <!-- <div class="action-buttons">
              <div class="action-button">
                <img
                  src="@/assets/pages/card/export.png"
                  :alt="$t('public.export')"
                />
                <span>{{ $t("public.export") }}</span>
              </div>
              <div class="action-button">
                <img
                  src="@/assets/pages/card/multiply.png"
                  :alt="$t('public.batch')"
                />
                <span>{{ $t("public.batch") }}</span>
              </div>
            </div> -->
          </div>

          <el-tabs v-model="activeTab" @tab-click="tabClick">
            <el-tab-pane
              :label="$t(v.label)"
              :name="v.name"
              v-for="(v, i) in tabList"
              :key="i"
            >
              <div class="filters">
                <el-select
                  style="width: 120px"
                  v-model="timeframe"
                  :placeholder="$t('public.timeframe')"
                  class="filter-select"
                  @change="handleRangeChange"
                >
                  <el-option
                    v-for="(option, i) in ranges"
                    :key="i"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>

                <el-date-picker
                  v-if="timeframe == 'customized'"
                  style="max-width: 400px"
                  v-model="dateRange"
                  type="daterange"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :placeholder="$t('public.select_date_range')"
                  :start-placeholder="$t('public.start_date')"
                  :end-placeholder="$t('public.end_date')"
                  class="filter-date-picker"
                />
                <el-button type="primary" @click="getCardHistoryList"
                  >查询</el-button
                >
              </div>

              <!-- 统计 -->
              <div style="width: 100%" v-if="sum">
                <el-divider />
                <!-- <div class="flex-end" @click="showEchart = !showEchart">
                  <el-button>切换图表</el-button>
                </div>
                <div
                  v-show="showEchart"
                  id="statementContainer"
                  ref="statementContainer"
                  style="width: 100%; height: 400px"
                ></div> -->
                <!-- 统计数据 -->
                <el-row id="statistic" justify="center" v-show="!showEchart">
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.recharge_amount')"
                      :value="sum.recharge_amount"
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.recharge_cost')"
                      :value="sum.recharge_cost"
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.card_opening_cost')"
                      :value="sum.card_opening_cost"
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.card_opening_income')"
                      :value="sum.card_opening_income"
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.topup_amount')"
                      :value="sum.topup_amount"
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.topup_cost')"
                      :value="sum.topup_cost"
                    />
                  </el-col>
                  <el-col :span="3">
                    <el-statistic
                      :title="$t('public.cell_title.topup_income')"
                      :value="sum.topup_income"
                    />
                  </el-col>
                </el-row>
                <el-divider />
              </div>

              <div class="card-table">
                <el-table
                  :data="cards"
                  border
                  row-key="biz_id"
                  style="width: 100%"
                  :empty-text="$t('public.no_data')"
                  @selection-change="handleSelectionChange"
                >
                  <!-- <el-table-column
                    type="selection"
                    :selectable="selectable"
                    width="55"
                  /> -->

                  <!-- <el-table-column
                    prop="merchant"
                    :label="$t('public.cell_title.merchant')"
                  /> -->
                  <el-table-column
                    prop="card_uid"
                    :label="$t('public.cell_title.card_uid')"
                  >
                    <template #default="scope">
                      <div class="card-container">
                        <el-popover
                          placement="top-start"
                          :width="20"
                          trigger="hover"
                        >
                          <template #reference>
                            <span class="cut">{{ scope.row.card_uid }}</span>
                          </template>
                          <template #default>
                            <div
                              class="copy tag-copy"
                              :data-clipboard-text="scope.row.card_uid"
                              @click="copyText(scope.row.card_uid)"
                            >
                              {{ $t("public.copy") }}
                            </div>
                          </template>
                        </el-popover>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="number_ending"
                    :label="$t('public.cell_title.number_ending')"
                  />
                  <el-table-column
                    prop="biz_time"
                    sortable
                    :label="$t('public.cell_title.biz_time')"
                  >
                    <template #default="scope">
                      <span>{{
                        formatDateString(scope.row.biz_time * 1000)
                      }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
          prop="biz_id"
          :label="$t('public.cell_title.biz_id')"
        /> -->
                  <el-table-column
                    prop="recharge_amount"
                    sortable
                    :label="$t('public.cell_title.recharge_amount')"
                  />
                  <el-table-column
                    prop="recharge_cost"
                    sortable
                    :label="$t('public.cell_title.recharge_cost')"
                  />

                  <el-table-column
                    prop="card_opening_cost"
                    sortable
                    :label="$t('public.cell_title.card_opening_cost')"
                  />
                  <el-table-column
                    prop="card_opening_income"
                    :label="$t('public.cell_title.card_opening_income')"
                  />
                  <el-table-column
                    prop="topup_amount"
                    sortable
                    :label="$t('public.cell_title.topup_amount')"
                  />
                  <el-table-column
                    prop="topup_cost"
                    sortable
                    :label="$t('public.cell_title.topup_cost')"
                  />
                  <el-table-column
                    prop="topup_income"
                    sortable
                    :label="$t('public.cell_title.topup_income')"
                  />
                  <!-- <el-table-column prop="uid" :label="$t('public.cell_title.uid')" /> -->
                  <!-- <el-table-column :label="$t('public.details')" width="300">
                    <template #default="scope">
                      <div class="action-buttons-container">
                        <span @click="handleAction('details', scope.row)"
                          >查看订单</span
                        >
                      </div>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pageInfo.total"
              :page-size="pageInfo.pageSize"
              v-model:current-page="pageInfo.page"
              @change="onChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuLayout from "@/components/layouts/MenuLayout.vue";
import StatusbarLayout from "@/components/layouts/StatusbarLayout.vue";
import apiClient from "@/services/api";
import {
  ref,
  reactive,
  onMounted,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
} from "vue";
import { formatDateString } from "@/utils/format/date.js";
import dayjs from "dayjs";
import * as echarts from "echarts";

const globalProperties =
  getCurrentInstance().appContext.config.globalProperties;

const activeTab = ref("all");
const dateRange = ref([]); //查询参数-时间
const timeframe = ref(); //查询参数-时间范围
const tabList = reactive([
  {
    key: 1,
    name: "all",
    label: "public.all",
    num: 0,
  },
  // {
  //   key: 2,
  //   name: "recharge", // *
  //   num: 0,
  //   label: "public.recharge",
  // },
  // {
  //   key: 3,
  //   name: "chargebacks", // *
  //   num: 0,
  //   label: "public.chargebacks",
  // },
]);
const cards = ref([]);
const pageInfo = ref({
  page: 1,
  pageSize: 10,
  total: 0,
});

// 预设时间范围选项
const ranges = reactive([
  {
    value: "thisWeek",
    label: globalProperties.$t("public.time_frame.this_week"),
  },
  {
    value: "thisMonth",
    label: globalProperties.$t("public.time_frame.this_month"),
  },
  {
    value: "lastWeek",
    label: globalProperties.$t("public.time_frame.last_week"),
  },
  {
    value: "lastMonth",
    label: globalProperties.$t("public.time_frame.last_month"),
  },
  {
    value: "last7Days",
    label: globalProperties.$t("public.time_frame.last7Days"),
  },
  {
    value: "last30Days",
    label: globalProperties.$t("public.time_frame.last30Days"),
  },
  { value: "allTime", label: globalProperties.$t("public.time_frame.allTime") },
  {
    value: "customized",
    label: globalProperties.$t("public.time_frame.customized"),
  },
]);

// 根据选择的时间范围设置日期
const handleRangeChange = (value) => {
  switch (value) {
    case "thisWeek":
      dateRange.value = [
        dayjs().startOf("week").format("YYYY-MM-DD"),
        dayjs().endOf("week").format("YYYY-MM-DD"),
      ];
      break;
    case "thisMonth":
      dateRange.value = [
        dayjs().startOf("month").format("YYYY-MM-DD"),
        dayjs().endOf("month").format("YYYY-MM-DD"),
      ];
      break;
    case "lastWeek":
      dateRange.value = [
        dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
        dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
      ];
      break;
    case "lastMonth":
      dateRange.value = [
        dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      ];
      break;
    case "last7Days":
      dateRange.value = [
        dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
      break;
    case "last30Days":
      dateRange.value = [
        dayjs().subtract(30, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
      break;
    case "allTime":
      dateRange.value = [];
      break;
    case "customized":
      dateRange.value = [];
      break;
  }
};

// 勾选行
const multipleSelection = ref([]);
const selectable = (row) => {
  // return ![1, 2].includes(row.id) // 第一二行不可选
  return true;
};
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  console.log(multipleSelection.value.length);
};

// 操作行数据 操作action, 行row
const handleAction = (action, row) => {
  console.log(action, row);
};

// 点击标签页-手动赋值调用，避免el-tabs组件更新activeTab不及时造成数据滞后
const tabClick = (tab) => {
  activeTab.value = tab.props.name;
  getCardHistoryList();
};

// 获取收支明细列表
const sum = ref();
const getCardHistoryList = async () => {
  const data = await apiClient.post("/account/statement", {
    start_time: dateRange.value[0] ? dateRange.value[0] : null,
    end_time: dateRange.value[1] ? dateRange.value[1] : null,
    page: pageInfo.value.page,
    per_page: pageInfo.value.pageSize,
  });
  if (data.code == 0 && data.data) {
    cards.value = data.data.items;
    sum.value = data.data.sum;
    pageInfo.value.page = data.data.page;
    pageInfo.value.pageSize = data.data.per_page;
    pageInfo.value.total = data.data.total_count;
  }
};
// 分页改变-同步分页当前页数和页大小，重新调用getCardList
const onChange = (page, pageSize) => {
  pageInfo.value.page = page;
  pageInfo.value.pageSize = pageSize;
  getCardHistoryList();
};
// echarts 图表
const statementContainer = ref(null);
const showEchart = ref(false);

const setEcharts = () => {
  if (statementContainer.value) {
    const clientWidth = document.getElementById("statistic").clientWidth;

    const myChart = echarts.init(statementContainer.value[0], null, {
      width: clientWidth ? clientWidth : 600,
    });

    const option = {
      title: {
        text: "财务数据展示",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [
          globalProperties.$t("public.cell_title.card_opening_cost"),
          globalProperties.$t("public.cell_title.card_opening_income"),
          globalProperties.$t("public.cell_title.recharge_amount"),
          globalProperties.$t("public.cell_title.recharge_cost"),
          globalProperties.$t("public.cell_title.topup_amount"),
          globalProperties.$t("public.cell_title.topup_cost"),
          globalProperties.$t("public.cell_title.topup_income"),
        ],
      },
      xAxis: {
        data: [
          globalProperties.$t("public.cell_title.card_opening_cost"),
          globalProperties.$t("public.cell_title.card_opening_income"),
          globalProperties.$t("public.cell_title.recharge_amount"),
          globalProperties.$t("public.cell_title.recharge_cost"),
          globalProperties.$t("public.cell_title.topup_amount"),
          globalProperties.$t("public.cell_title.topup_cost"),
          globalProperties.$t("public.cell_title.topup_income"),
        ],
      },
      yAxis: { type: "value" },
      series: [
        {
          name: "金额",
          type: "bar",
          data: [
            sum.value.card_opening_cost,
            sum.value.card_opening_income,
            sum.value.recharge_amount,
            sum.value.recharge_cost,
            sum.value.topup_amount,
            sum.value.topup_cost,
            sum.value.topup_income,
          ],
        },
      ],
    };

    myChart.setOption(option);

    // 监听窗口大小变化，重新调整图表大小
    const handleResize = () => myChart.resize();
    window.addEventListener("resize", handleResize);

    // 清理图表
    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    });
  }
};

onMounted(() => {
  getCardHistoryList().then(() => {
    nextTick(() => {
      setEcharts();
    });
  });
});
</script>

<style scoped>
#statementContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.displayNone {
  display: none !important;
}
.copy {
  cursor: pointer;
}
.flex-end {
  display: flex;
  justify-content: end;
}
.main-content {
  width: 82%;
  background-color: #f7fafa;
}

:deep .el-table .cell {
  white-space: nowrap; /* 强制文本在一行显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 溢出时显示省略号 */
  padding: 10px 5px;
}

.content-container {
  background: #ffffff;
  margin: 8px;
}

.content {
  background: #fff;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  /* max-width: 1350px; */
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  align-items: center;
}

.card-table {
  margin-top: 20px;
}

.card-logo {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.card-refresh {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left: 10px;
}

#card-page {
  display: flex;
  background-color: #f7fafa;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.filter-input {
  width: 150px;
}

.filter-select {
  width: 100px;
}

.filter-date-picker {
  width: 150px;
}

.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.action-buttons-container {
  display: flex;
  gap: 10px;
}

.action-buttons-container span {
  color: #4baaf4;
  font-size: 0.9rem;
  cursor: pointer;
}

.action-buttons-container span:hover {
  color: #0479d3;
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-button {
  display: flex;
  align-items: center;
  padding: 6px 20px;
  border: 1px solid #4baaf4;
  border-radius: 7px;
  cursor: pointer;
  background-color: #fff;
  font-size: 1rem;
  color: #4baaf4;
  gap: 10px;
}

.action-button img {
  width: 15px;
  height: 15px;
}

.action-button:hover {
  opacity: 0.7;
}

.status-span {
  padding: 2px 10px;
  border-radius: 5px;
  display: inline-block;
}

.status-active {
  color: #17d000;
  background-color: #c5ffbe;
}

.status-frozen {
  color: #ff0000;
  background-color: #ffbebe;
}

.status-icon {
  margin-left: 1px;
  width: 7px;
  height: auto;
  position: relative;
  top: -2px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* el-table样式调整 start */

.el-table {
  --el-table-border-color: none;
  --el-table-border: 1px dashed #d4d4d4;
}

::v-deep .el-tabs__item.is-active,
.el-tabs__item:hover {
  color: #fff;
  background-color: #000;
  border-radius: 17px 17px 0px 0px;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

::v-deep .el-tabs__active-bar {
  background: none;
}

::v-deep .el-tabs__item {
  font-size: 1rem;
}

.el-button--small {
  font-size: 0.9rem;
}

::v-deep .el-tabs__nav-wrap:after {
  background-color: #000;
}

/* el-table样式调整 end */
</style>
