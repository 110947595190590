<template>
  <router-view />
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import useInfoStore from "@/stores/user"; //引入仓库
const infoStore = useInfoStore();

const router = useRouter();
onMounted(() => {
  const token = localStorage.getItem("jwt_token");
  router.push({ name: token ? "Product" : "Login" });
});
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

/* 解决 自动填充 字体变白 */
:deep input:-webkit-autofill,
:deep textarea:-webkit-autofill,
:deep select:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}
</style>