<template>
  <div class="top-nav">
    <img
      class="locale-icon"
      src="@/assets/icons/locale.png"
      alt="Language"
      @click="changeLang"
    />
    <div class="language-switcher"></div>
    <div class="user-info">
      <img src="@/assets/icons/circle_person.png" alt="User Icon" />
      <span class="username">{{ userInfo.user }}</span>
      <el-popover placement="top-start" trigger="hover">
        <template #reference>
          <img
            class="logout-icon relative-position"
            src="@/assets/icons/arrow_down.png"
            alt="Logout"
          />
        </template>
        <!-- 默认插槽自定义内容 -->
        <template #default>
          <div class="quit" @click="quit">
            <img
              class="quit-icon"
              src="@/assets/icons/quit_icon.png"
              alt="Logout"
            />
            <span class="quit-cont">{{ $t("login.logout") }}</span>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script setup>
import useInfoStore from "@/stores/user"; //引入仓库
import router from "@/router";
import { ref, onMounted } from "vue";

const userInfo = ref({
  user: "214567896@163.com",
});

onMounted(() => {
  if (localStorage.getItem("jwt_userInfo")) {
    userInfo.value = JSON.parse(localStorage.getItem("jwt_userInfo"));
  }
});
// 中英文切换
const changeLang = () => {
  const infoStore = useInfoStore();
  infoStore.changeLocale();
};
// 退出登录
const quit = () => {
  const infoStore = useInfoStore();
  infoStore.remove_token();
  router.push({ name: "Login" });
};
</script>

<style scoped>
/* 退出登录弹出框 */
.quit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  cursor: pointer;
}
.quit-icon {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}
.quit-cont {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #707070;
  line-height: 0px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
/* 将 top-nav 和相关样式移到这里 */
.top-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  padding: 18px 30px;
}

.locale-icon {
  cursor: pointer;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.language-switcher {
  margin-right: 30px;
  font-size: 16px;
}

.user-info {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.user-info .username {
  font-size: 18px;
  color: #707070;
}

.user-info img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.relative-position {
  position: relative;
  top: 2px;
}

.logout-icon {
  margin-left: 9px !important;
  width: 11px !important;
  height: 7px !important;
}
</style>
