<template>
  <div id="card-page">
    <MenuLayout />
    <div class="main-content">
      <StatusbarLayout />
      <div class="content-container">
        <div class="content">
          <div class="title">
            <h2>{{ $t("public.card_manage") }}</h2>
            <div class="action-buttons">
              <div class="action-button" @click="dialogVisible = true">
                <img
                  src="@/assets/pages/card/add.png"
                  :alt="$t('public.wildcards')"
                />
                <span>{{ $t("public.wildcards") }}</span>
              </div>
              <!-- <div class="action-button">
                <img
                  src="@/assets/pages/card/export.png"
                  :alt="$t('public.export')"
                />
                <span>{{ $t("public.export") }}</span>
              </div>
              <div class="action-button">
                <img
                  src="@/assets/pages/card/multiply.png"
                  :alt="$t('public.batch')"
                />
                <span>{{ $t("public.batch") }}</span>
              </div> -->
            </div>
          </div>

          <el-tabs v-model="activeTab" @tab-click="tabClick">
            <el-tab-pane
              :label="$t('' + v.label + '')"
              :name="v.name"
              v-for="(v, i) in tabList"
              :key="i"
            >
              <div class="filters">
                <el-input
                  :placeholder="$t('public.last_four')"
                  class="filter-input"
                  v-model.trim="last_four"
                  @keydown.enter="getCardList"
                />
                <el-input
                  :placeholder="$t('public.addr_last_four')"
                  class="filter-input"
                  v-model.trim="addr_last_four"
                  @keydown.enter="getCardList"
                />
                <!-- <el-select
                  v-model="selectedSegment"
                  :placeholder="$t('public.status')"
                  class="filter-select"
                >
                  <el-option :label="$t('public.all')" value="all"></el-option>
                  <el-option
                    :label="$t('public.in_use')"
                    :value="1"
                  ></el-option>
                  <el-option
                    :label="$t('public.frozen')"
                    :value="2"
                  ></el-option>
                </el-select> -->
                <!-- <el-input
                  :placeholder="$t('public.all_tags')"
                  class="filter-input"
                /> -->
                <el-input
                  :placeholder="$t('public.cardholder_name')"
                  class="filter-input"
                  v-model.trim="cardholder_name"
                  @keydown.enter="getCardList"
                />
                <!-- <el-input
                  :placeholder="$t('public.all_card_types')"
                  class="filter-input"
                /> -->
                <el-date-picker
                  style="max-width: 400px"
                  v-model="dateRange"
                  type="daterange"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :placeholder="$t('public.select_date_range')"
                  :start-placeholder="$t('public.start_date')"
                  :end-placeholder="$t('public.end_date')"
                  class="filter-date-picker"
                  @keydown.enter="getCardList"
                />
                <el-button type="primary" @click="getCardList">查询</el-button>
              </div>

              <div class="card-table">
                <el-table
                  :data="cards"
                  style="width: 100%"
                  row-key="card_uid"
                  border
                  :empty-text="$t('public.no_data')"
                  @selection-change="handleSelectionChange"
                >
                  <!-- <el-table-column
                    type="selection"
                    :selectable="selectable"
                    width="55"
                  /> -->
                  <el-table-column prop="card_uid" label="ID">
                    <template #default="scope">
                      <div class="card-container">
                        <!-- <img
                          :src="scope.row.logo"
                          alt="Card Logo"
                          class="card-logo"
                        /> -->
                        <el-popover
                          placement="top-start"
                          :width="20"
                          trigger="hover"
                        >
                          <template #reference>
                            <!-- <span>{{ scope.row.card_uid.slice(0, 6) }}...</span> -->
                            <span class="cut">{{ scope.row.card_uid }}</span>
                          </template>
                          <template #default>
                            <div
                              class="copy tag-copy"
                              :data-clipboard-text="scope.row.card_uid"
                              @click="copyText(scope.row.card_uid)"
                            >
                              {{ $t("public.copy") }}
                            </div>
                          </template>
                        </el-popover>
                        <!-- <img
                          src="@/assets/pages/card/copy.png"
                          @click="copyText(scope.row.card_uid)"
                          class="card-refresh"
                          :alt="$t('public.copy')"
                        /> -->
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="first_name"
                    :label="$t('public.cardholder')"
                    width="100"
                  >
                    <template #default="scope">
                      <div class="card-container">
                        <span
                          >{{ scope.row.first_name }}
                          {{ scope.row.last_name }}</span
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="addrs"
                    :label="$t('public.recharge_address')"
                    width="190"
                  >
                    <template #default="scope">
                      <div
                        class="card-container"
                        v-if="scope.row.addrs && scope.row.addrs.length > 0"
                        @click="openRechargeDialog(scope.row.addrs)"
                      >
                        <div v-for="(v, i) in scope.row.addrs" :key="i">
                          <a href="#">{{ v.chain }} &nbsp;</a>
                        </div>
                      </div>
                      <div v-else>-</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="email"
                    :label="$t('public.email')"
                    width="150"
                    class="cut"
                  >
                    <template #default="scope">
                      <div class="card-container">
                        <span class="cut">{{ scope.row.email }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="phone"
                    :label="$t('public.phone')"
                    width="115"
                  />
                  <el-table-column
                    prop="status"
                    :label="$t('public.card_status')"
                    width="100"
                  >
                    <template #default="scope">
                      <template v-for="(v, i) in CardStatus" :key="i">
                        <el-tag
                          v-if="scope.row.status === v.value"
                          :type="v.type"
                          :effect="v.effect"
                        >
                          {{ $t(v.label) }}
                        </el-tag>
                      </template>
                      <!-- 动态样式绑定，英文后 使用中!= inuse -->
                      <!-- <span
                        class="status-span status-active"
                        v-if="scope.row.status === 1"
                      >
                        {{ $t("public.in_use") }}
                        <img
                          :src="
                            require('@/assets/pages/card/using_down_arrow.png')
                          "
                          alt="status icon"
                          class="status-icon"
                        />
                      </span>
                      <span
                        class="status-span status-frozen"
                        v-else-if="scope.row.status === 2"
                      >
                        {{ $t("public.frozen") }}
                        <img
                          :src="
                            require('@/assets/pages/card/frozen_down_arrow.png')
                          "
                          alt="status icon"
                          class="status-icon"
                        />
                      </span>
                      <span class="status-span" v-else>
                        {{ $t("public.unlicensed") }}
                        <img
                          :src="
                            require('@/assets/pages/card/black_down_arrow.png')
                          "
                          alt="status icon"
                          class="status-icon"
                        />
                      </span> -->
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="number"
                    :label="$t('public.card_number')"
                    width="150"
                  >
                    <template #default="scope">
                      <div class="card-container">
                        <span v-if="scope.row.number">{{
                          scope.row.number
                        }}</span>
                        <span v-else>-</span>
                      </div>
                      <!-- <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.number"
                        placement="top"
                      >
                        <div class="card-container">
                          <span v-if="scope.row.number">{{
                            scope.row.number
                          }}</span>
                          <span v-else>-</span>
                        </div>
                      </el-tooltip> -->
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="balance"
                    :label="$t('public.balance')"
                    width="115"
                  />
                  <el-table-column
                    prop="create_time"
                    :label="$t('public.create_time')"
                    sortable
                    :sort-method="sortTime"
                    width="165"
                  />
                  <el-table-column :label="$t('public.operation')" width="300">
                    <template #default="scope">
                      <div class="action-buttons-container">
                        <!-- <el-tooltip
                          effect="dark"
                          :content="$t('public.view')"
                          placement="top"
                        >
                          <img
                            :src="
                              require('@/assets/pages/card/check_consumption_records.png')
                            "
                            class="card-refresh"
                            :alt="$t('public.view')"
                            @click="handleAction('view', scope.row)"
                          />
                        </el-tooltip> -->
                        <!-- 刷新 -->
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.refresh')"
                          placement="top"
                        >
                          <img
                            src="@/assets/pages/card/refresh.png"
                            class="card-refresh"
                            :alt="$t('public.refresh')"
                            @click="handleAction('refresh', scope.row)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.send_one_link')"
                          placement="top"
                        >
                          <img
                            :src="
                              require('@/assets/pages/card/send_one_link.png')
                            "
                            class="card-refresh"
                            :alt="$t('public.send_one_link')"
                            @click="handleAction('send_one_link', scope.row)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.freeze')"
                          placement="top"
                        >
                          <img
                            :src="require('@/assets/pages/card/freeze.png')"
                            class="card-refresh"
                            :alt="$t('public.freeze')"
                            @click="handleAction('freeze', scope.row)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.unfreeze')"
                          placement="top"
                        >
                          <img
                            :src="require('@/assets/pages/card/unfreeze.png')"
                            class="card-refresh"
                            :alt="$t('public.unfreeze')"
                            @click="handleAction('unfreeze', scope.row)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.limit_transfer')"
                          placement="top"
                        >
                          <img
                            :src="
                              require('@/assets/pages/card/limit_transfer.png')
                            "
                            class="card-refresh"
                            :alt="$t('public.limit_transfer')"
                            @click="handleAction('limit_transfer', scope.row)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.check_consumption_records')"
                          placement="top"
                        >
                          <img
                            :src="
                              require('@/assets/pages/card/check_consumption_records.png')
                            "
                            class="card-refresh"
                            :alt="$t('public.check_consumption_records')"
                            @click="
                              handleAction(
                                'check_consumption_records',
                                scope.row
                              )
                            "
                          />
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="$t('public.check_recharge_records')"
                          placement="top"
                        >
                          <img
                            :src="
                              require('@/assets/pages/card/check_consumption_records.png')
                            "
                            class="card-refresh"
                            :alt="$t('public.check_recharge_records')"
                            @click="
                              handleAction('check_recharge_records', scope.row)
                            "
                          />
                        </el-tooltip>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pageInfo.total"
              :page-size="pageInfo.pageSize"
              v-model:current-page="pageInfo.page"
              @change="onChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 开卡模态框 -->
    <el-dialog
      v-model="dialogVisible"
      :title="$t('public.wildcards')"
      :before-close="cancelForm"
    >
      <div>
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          label-width="auto"
          status-icon
        >
          <el-form-item :label="$t('public.product')" prop="prod_id">
            <el-select
              v-model="form.prod_id"
              :placeholder="$t('public.select_placeholder')"
              @click="getProductList"
            >
              <el-option
                v-for="(v, i) in productList"
                :key="i"
                :label="v.name"
                :value="v.id"
              />
            </el-select>
          </el-form-item>
          <el-row justify="space-between">
            <el-col :span="12">
              <el-form-item :label="$t('public.first_name')" prop="first_name">
                <el-input
                  v-model.trim="form.first_name"
                  :placeholder="$t('public.input_placeholder')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('public.last_name')" prop="last_name">
                <el-input
                  v-model.trim="form.last_name"
                  :placeholder="$t('public.input_placeholder')"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row justify="space-between">
            <el-col :span="12">
              <el-form-item
                :label="$t('public.country_code')"
                prop="phone_code"
              >
                <el-input
                  v-model.trim="form.phone_code"
                  :placeholder="$t('public.input_placeholder')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('public.phone')" prop="phone">
                <el-input
                  v-model.trim="form.phone"
                  :placeholder="$t('public.input_placeholder')"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item :label="$t('public.email')" prop="email">
            <el-input
              v-model.trim="form.email"
              :placeholder="$t('public.input_placeholder')"
            />
          </el-form-item>
          <el-form-item :label="$t('public.remark')" prop="remark">
            <el-input
              v-model.trim="form.remark"
              :placeholder="$t('public.input_placeholder')"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="confirmForm">
            {{ $t("public.confirm") }}
          </el-button>
          <el-button @click="cancelForm">{{ $t("public.cancel") }}</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 额度转移模态框 -->
    <el-dialog
      v-model="transferDialogVisible"
      :title="$t('public.limit_transfer')"
      :before-close="transferCancelForm"
    >
      <el-form
        ref="transferFormRef"
        :model="transferForm"
        :rules="transferRules"
        label-width="auto"
        status-icon
      >
        <el-form-item :label="$t('public.transfer_card_id')" prop="from_uid">
          <el-input
            v-model="transferForm.from_uid"
            :placeholder="$t('public.input_placeholder')"
            disabled
          />
        </el-form-item>
        <el-form-item :label="$t('public.target_card_id')" prop="to_uid">
          <el-input
            v-model="transferForm.to_uid"
            :placeholder="$t('public.input_placeholder')"
          />
        </el-form-item>
        <el-form-item :label="$t('public.amount')" prop="amount">
          <el-input
            v-model.trim="transferForm.amount"
            :placeholder="$t('public.input_placeholder')"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="transferConfirmForm">
            {{ $t("public.confirm") }}
          </el-button>
          <el-button @click="transferCancelForm">{{
            $t("public.cancel")
          }}</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 消费记录 or 充值记录 模态框 -->
    <el-dialog
      v-model="recordsDialogVisible"
      :title="$t(recordsTitle)"
      :before-close="recordsCancelForm"
    >
      <el-form
        ref="recordsFormRef"
        :model="recordsForm"
        :rules="recordsRules"
        label-width="auto"
        status-icon
      >
        <el-form-item :label="$t('public.time')" prop="time">
          <el-select
            style="width: 120px"
            v-model="timeframe"
            :placeholder="$t('public.timeframe')"
            class="filter-select"
            @change="handleRangeChange"
          >
            <el-option
              v-for="(option, i) in ranges"
              :key="i"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-if="timeframe == 'customized'"
            style="max-width: 300px; margin-left: 20px"
            v-model="recordsForm.daterange"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :placeholder="$t('public.select_date_range')"
            :start-placeholder="$t('public.start_date')"
            :end-placeholder="$t('public.end_date')"
            class="filter-date-picker"
          />
          <el-button
            type="primary"
            style="margin-left: 20px"
            @click="selectRecords"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div class="records">
        <!-- 消费 -->
        <div v-if="recordsUrl == '/card/consumption/records'">
          <el-table
            :data="records"
            style="width: 100%"
            :empty-text="$t('public.no_data')"
          >
            <el-table-column prop="amount" :label="$t('public.amount')" />
            <el-table-column prop="currency" :label="$t('public.currency')" />
            <el-table-column prop="createTime" :label="$t('public.time')">
              <template #default="scope">
                <!-- <span>{{ formatDateString(scope.row.createTime) }}</span> -->
                <span>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="fee" :label="$t('public.fee')" />
            <el-table-column prop="status" :label="$t('public.status')" />
          </el-table>
        </div>
        <!-- 充值 -->
        <div v-if="recordsUrl == '/card/deposit/records'">
          <el-table
            :data="records"
            style="width: 100%"
            :empty-text="$t('public.no_data')"
          >
            <el-table-column prop="amount" :label="$t('public.amount')" />
            <el-table-column prop="type" :label="$t('public.type')" />
            <el-table-column prop="createTime" :label="$t('public.time')">
              <template #default="scope">
                <!-- <span>{{ formatTimestamp(scope.row.createTime) }}</span> -->
                <span>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="recordsForm.total"
            :page-size="recordsForm.per_page"
            v-model:current-page="recordsForm.page"
            @change="onRecordChange"
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 充值地址展示框 -->
    <el-dialog
      v-model="rechargeDialogVisible"
      :title="$t('public.recharge_address')"
      :before-close="rechargeCancelForm"
    >
      <div class="records">
        <!-- 充值 -->
        <div>
          <el-table
            :data="rechargeList"
            style="width: 100%"
            :empty-text="$t('public.no_data')"
          >
            <el-table-column prop="chain" :label="$t('public.chain')" />
            <el-table-column prop="addr" :label="$t('public.addr')" />
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 查看模态框 -->
    <el-dialog
      v-model="viewDialogVisible"
      :title="$t('public.view')"
      :before-close="viewCancelForm"
    >
      <div class="view">
        <table border>
          <tr>
            <td>{{ viewData }}</td>
          </tr>
        </table>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import MenuLayout from "@/components/layouts/MenuLayout.vue";
import StatusbarLayout from "@/components/layouts/StatusbarLayout.vue";
import apiClient from "@/services/api";
import { ElMessage } from "element-plus";
import useClipboard from "vue-clipboard3";
import { ElLoading } from "element-plus";
import { formatDateString, formatTimestamp } from "@/utils/format/date.js";
import { onMounted, reactive, ref } from "vue";
import { getCurrentInstance } from "vue";
import axios from "axios";
import * as XLSX from "xlsx";
import { exportExcel } from "@/utils/export/xlsx";
import { CardStatus } from "@/enum/cardStatus";
import dayjs from "dayjs";

const {
  appContext: {
    config: { globalProperties },
  },
} = getCurrentInstance();

const selectedSegment = ref("all"); // 选择卡段
const dialogVisible = ref(false); // 模态框标识
const last_four = ref(""); //查询参数-开卡后四位
const addr_last_four = ref(""); //查询参数-充值地址后四位
const cardholder_name = ref(""); //查询参数-持卡人
const dateRange = ref([]); //查询参数-时间区间
const activeTab = ref("all");
const tabList = reactive([
  {
    key: 1,
    name: "all",
    label: "public.all",
    num: 0,
  },
  {
    key: 2,
    name: 1,
    num: 0,
    label: "public.in_use",
  },
  {
    key: 3,
    name: 2,
    num: 0,
    label: "public.frozen",
  },
]);
const cards = ref([]);
const form = ref({
  prod_id: "",
  first_name: "",
  last_name: "",
  phone_code: "",
  phone: "",
  email: "",
  remark: "",
});

const rules = ref({
  prod_id: [
    {
      required: true,
      message: globalProperties.$t("public.prod_reminder"),
    },
  ],
  last_name: [
    {
      required: true,
      message: globalProperties.$t("public.last_name_reminder"),
      trigger: "blur",
    },
  ],
  first_name: [
    {
      required: true,
      message: globalProperties.$t("public.first_name_reminder"),
      trigger: "blur",
    },
  ],
  phone_code: [
    {
      required: true,
      message: globalProperties.$t("public.country_code_reminder"),
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: globalProperties.$t("public.phone_reminder"),
      trigger: "blur",
    },
    // {
    //   type: "number",
    //   message: globalProperties.$t("public.it_must_be_a_number"),
    // },
  ],
  email: [
    {
      required: true,
      message: globalProperties.$t("public.email_reminder"),
      trigger: "blur",
    },
  ],
});
const pageInfo = reactive({
  page: 1,
  pageSize: 10,
  total: 0,
});
const transferDialogVisible = ref(false); //额度转移模态框
const transferForm = ref({
  from_uid: "",
  to_uid: "",
  amount: "",
});
const transferRules = ref({
  to_uid: [
    {
      required: true,
      message: globalProperties.$t("public.transfer_target_card_id_reminder"),
    },
  ],
  amount: [
    {
      required: true,
      message: globalProperties.$t("public.transfer_amount_reminder"),
    },
    // {
    //   type: "number",
    //   message: globalProperties.$t("public.it_must_be_a_number"),
    // },
  ],
});
const recordsTitle = ref("public.check_consumption_records"); //消费记录 or 充值记录 模态框标题
const recordsUrl = ref("");
const recordsDialogVisible = ref(false);
const recordsForm = ref({
  card_uid: "",
  start_time: "",
  end_time: "",
  page: 1,
  per_page: 5,
  daterange: [],
  total: 0,
});
const recordsRules = ref({});
const records = ref(""); // 消费记录 or 充值记录

// 勾选行
const multipleSelection = ref([]);
const selectable = (row) => {
  // return ![1, 2].includes(row.id) // 第一二行不可选
  return true;
};
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  console.log(multipleSelection.value.length);
};

// 刷新卡片信息
const refreshCard = async (row) => {
  const data = await apiClient.post("/card/refresh", {
    card_uid: row.card_uid,
  });
  if (data.code == 0) {
    getCardList();
  } else {
    ElMessage({ message: data.message, type: "error" });
  }
};

// 操作行数据 操作action, 行row
const handleAction = async (action, row) => {
  const loadingInstance = ElLoading.service();
  // console.log(action, row);
  if (action == "send_one_link") {
    // 发送一次性链接
    const data = await apiClient.post("/card/oncelink", {
      card_uid: row.card_uid,
    });
    if (data.code == 0) {
      ElMessage({
        message: globalProperties.$t("public.sent_successfully"),
        type: "success",
      });
    } else {
      ElMessage({ message: data.message, type: "error" });
    }
  } else if (action == "freeze") {
    // 冻结
    const data = await apiClient.post("/card/freeze", {
      card_uid: row.card_uid,
    });
    if (data.code == 0) {
      ElMessage({
        message: globalProperties.$t("public.freeze_successful"),
        type: "success",
      });
      getCardList();
    } else {
      ElMessage({ message: data.message, type: "error" });
    }
  } else if (action == "unfreeze") {
    // 解冻
    const data = await apiClient.post("/card/unfreeze", {
      card_uid: row.card_uid,
    });
    if (data.code == 0) {
      ElMessage({
        message: globalProperties.$t("public.successful_defrosting"),
        type: "success",
      });
      getCardList();
    } else {
      ElMessage({ message: data.message, type: "error" });
    }
  } else if (action == "limit_transfer") {
    // 额度转移
    transferForm.value.from_uid = row.card_uid;
    transferDialogVisible.value = true;
  } else if (action == "check_consumption_records") {
    // 查询消费记录
    recordsTitle.value = "public.check_consumption_records";
    recordsForm.value.card_uid = row.card_uid;
    recordsUrl.value = "/card/consumption/records";
    recordsDialogVisible.value = true;
  } else if (action == "check_recharge_records") {
    // 查询充值记录
    recordsTitle.value = "public.check_recharge_records";
    recordsForm.value.card_uid = row.card_uid;
    recordsUrl.value = "/card/deposit/records";
    recordsDialogVisible.value = true;
  } else if (action == "view") {
    Object.assign(viewData.value, row);
    viewDialogVisible.value = true;
  } else if (action == "refresh") {
    // 刷新
    refreshCard(row);
  }
  loadingInstance.close();
};
// 点击标签页-手动赋值调用，避免el-tabs组件更新activeTab不及时造成数据滞后
const tabClick = (tab) => {
  activeTab.value = tab.props.name;
  getCardList();
};
// 获取卡片列表
const once = ref(1);
const getCardList = async () => {
  // inactive-无效未开卡0 active-使用中1 frozen-冻结2
  const loadingInstance = ElLoading.service();

  const data = await apiClient.post("/card/list", {
    start_time: dateRange.value[0] ? dateRange.value[0] : null,
    end_time: dateRange.value[1] ? dateRange.value[1] : null,
    status: activeTab.value == "all" ? null : activeTab.value,
    card_number: last_four.value ? last_four.value : null,
    name: cardholder_name.value ? cardholder_name.value : null,
    page: pageInfo.page,
    per_page: pageInfo.pageSize,
    address: addr_last_four.value ? addr_last_four.value : null,
  });
  if (data.code == 0 && data.data) {
    cards.value = data.data.items;
    pageInfo.page = data.data.page;
    pageInfo.pageSize = data.data.per_page;
    pageInfo.total = data.data.total_count;
    // if (once.value == 1) {
    //   tabList[0].num = data.data.total_count;
    //   tabList[1].num = cards.value.filter((card) => card.status === 1).length;
    //   tabList[2].num = cards.value.filter((card) => card.status === 2).length;
    //   once.value--;
    // }
  }
  loadingInstance.close();
};

// 表格排序 - 与默认相同
const sortTime = (a, b) => {
  a.create_time - b.create_time;
};

// 分页改变-同步分页当前页数和页大小，重新调用getCardList
const onChange = (page, pageSize) => {
  pageInfo.page = page;
  pageInfo.pageSize = pageSize;
  getCardList();
};
// 表单确认 - 开卡
const ruleFormRef = ref();
const confirmForm = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      const loadingInstance = ElLoading.service();
      const data = await apiClient.post("/card/create", form.value);
      // console.log(data);
      if (data.code == 0) {
        dialogVisible.value = false;
        ElMessage({
          message: globalProperties.$t("public.successful_card_opening"),
          type: "success",
        });
        getCardList();
        ruleFormRef.value.resetFields();
      } else {
        ElMessage({
          message: globalProperties.$t("public.system_is_busy"),
          type: "error",
        });
      }
      loadingInstance.close();
      dialogVisible.value = false;
    }
  });
};
// 取消-清空表单
const cancelForm = () => {
  dialogVisible.value = false;
  ruleFormRef.value.resetFields();
};
// 获取产品列表-用于开卡下拉框
const productList = ref([]);
const getProductList = async () => {
  const data = await apiClient.post("/prod/list", { page: 1, per_page: 99 });
  if (data.code == 0) {
    productList.value = data.data.items;
  }
};
// 复制卡号
const copyText = async (id) => {
  const { toClipboard } = useClipboard();
  await toClipboard(id);
  ElMessage({ message: globalProperties.$t("public.copied"), type: "success" });
};
// 额度转移确认
const transferFormRef = ref();
const transferConfirmForm = async () => {
  transferFormRef.value.validate(async (valid) => {
    if (valid) {
      const loadingInstance = ElLoading.service();
      const data = await apiClient.post("/card/transfer", transferForm.value);
      if (data.code == 0) {
        transferDialogVisible.value = false;
        ElMessage({
          message: globalProperties.$t("public.line_transfer_successful"),
          type: "success",
        });
        getCardList();
      } else {
        ElMessage({ message: data.message, type: "error" });
      }
      loadingInstance.close();
      transferDialogVisible.value = false;
    }
  });
};
// 额度转移取消
const transferCancelForm = () => {
  transferDialogVisible.value = false;
  transferFormRef.value.resetFields();
};
// 查询记录
const timeframe = ref(""); //查询参数-时间区间
// 预设时间范围选项
const ranges = reactive([
  {
    value: "thisWeek",
    label: globalProperties.$t("public.time_frame.this_week"),
  },
  {
    value: "thisMonth",
    label: globalProperties.$t("public.time_frame.this_month"),
  },
  {
    value: "lastWeek",
    label: globalProperties.$t("public.time_frame.last_week"),
  },
  {
    value: "lastMonth",
    label: globalProperties.$t("public.time_frame.last_month"),
  },
  {
    value: "last7Days",
    label: globalProperties.$t("public.time_frame.last7Days"),
  },
  {
    value: "last30Days",
    label: globalProperties.$t("public.time_frame.last30Days"),
  },
  { value: "allTime", label: globalProperties.$t("public.time_frame.allTime") },
  {
    value: "customized",
    label: globalProperties.$t("public.time_frame.customized"),
  },
]);

// 根据选择的时间范围设置日期
const handleRangeChange = (value) => {
  switch (value) {
    case "thisWeek":
      recordsForm.value.daterange = [
        dayjs().startOf("week").format("YYYY-MM-DD"),
        dayjs().endOf("week").format("YYYY-MM-DD"),
      ];
      break;
    case "thisMonth":
      recordsForm.value.daterange = [
        dayjs().startOf("month").format("YYYY-MM-DD"),
        dayjs().endOf("month").format("YYYY-MM-DD"),
      ];
      break;
    case "lastWeek":
      recordsForm.value.daterange = [
        dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
        dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
      ];
      break;
    case "lastMonth":
      recordsForm.value.daterange = [
        dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      ];
      break;
    case "last7Days":
      recordsForm.value.daterange = [
        dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
      break;
    case "last30Days":
      recordsForm.value.daterange = [
        dayjs().subtract(30, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];
      break;
    case "allTime":
      recordsForm.value.daterange = [];
      break;
    case "customized":
      recordsForm.value.daterange = [];
      break;
  }
};
const recordsFormRef = ref();
const selectRecords = async () => {
  recordsForm.value.start_time = recordsForm.value.daterange[0];
  recordsForm.value.end_time = recordsForm.value.daterange[1];
  recordsFormRef.value.validate(async (valid) => {
    if (valid) {
      const loadingInstance = ElLoading.service();
      const data = await apiClient.post(recordsUrl.value, recordsForm.value);

      if (data.code == 0) {
        // ElMessage({
        //   message: globalProperties.$t("public.successful_query"),
        //   type: "success",
        // });
        records.value = data.data.items;
        recordsForm.value.page = data.data.page;
        recordsForm.value.per_page = data.data.per_page;
        recordsForm.value.total = data.data.total_count;
      } else {
        ElMessage({ message: data.message, type: "error" });
      }
      loadingInstance.close();
      recordsDialogVisible.value = true;
    }
  });
};
const onRecordChange = (page, pageSize) => {
  recordsForm.value.page = page;
  recordsForm.value.per_page = pageSize;
  selectRecords();
};
// 消费记录 or 充值记录 模态框关闭-清空记录表单、记录、分页
const recordsCancelForm = () => {
  recordsDialogVisible.value = false;
  recordsFormRef.value.resetFields();
  records.value = "";
  recordsForm.value.page = 1;
  recordsForm.value.per_page = 5;
  recordsForm.value.total = 0;
};

// 充值地址
const rechargeDialogVisible = ref(false);
const rechargeList = ref();
const openRechargeDialog = (addrs) => {
  rechargeList.value = addrs;
  rechargeDialogVisible.value = true;
};
const rechargeCancelForm = () => {
  rechargeDialogVisible.value = false;
  rechargeList.value = "";
};

// 查看
const viewDialogVisible = ref(false);
const viewData = ref("");
const viewCancelForm = () => {
  viewDialogVisible.value = false;
  viewData.value = "";
};

//xlsx表头
const titleArr = ref([
  "充值地址",
  "余额",
  "ID",
  "消费",
  "创建时间",
  "邮箱",
  "名",
  "姓",
  "卡号",
  "手机号",
  "国家区号",
  "产品ID",
  "备注",
  "状态",
]);

// 导出选中数据
const exportSelection = () => {
  if (multipleSelection.value.length == 0) {
    ElMessage({
      message: globalProperties.$t("public.selection_least_one_reminder"),
      type: "warning",
    });
    return;
  }
  exportExcel(
    multipleSelection.value,
    "cards",
    titleArr.value,
    globalProperties.$t("public.card_list")
  );
};
onMounted(() => {
  getCardList();
  // getProductList();
});
</script>

<style scoped>
.cut {
  white-space: nowrap; /* 强制文本在一行显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 溢出时显示省略号 */
}
body {
  margin: 0;
}
.example-showcase .el-loading-mask {
  z-index: 9;
}
.copy {
  cursor: pointer;
}
.main-content {
  width: 82%;
  background-color: #f7fafa;
}

.content-container {
  background: #ffffff;
  margin: 8px;
}

.content {
  background: #fff;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  /* max-width: 1350px; */
  margin: 0 auto;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  align-items: center;
}

.card-table {
  margin-top: 20px;
}

.card-logo {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.card-refresh {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left: 10px;
}

#card-page {
  display: flex;
  background-color: #f7fafa;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.filter-input {
  width: 150px;
}

.filter-select {
  width: 150px;
}

.filter-date-picker {
  width: 150px;
}

.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  width: 80%;
}

.action-buttons-container {
  display: flex;
  gap: 10px;
}

.action-buttons-container span {
  color: #4baaf4;
  font-size: 0.9rem;
  cursor: pointer;
}

.action-buttons-container span:hover {
  color: #0479d3;
  text-decoration: underline;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-button {
  display: flex;
  align-items: center;
  padding: 6px 20px;
  border: 1px solid #4baaf4;
  border-radius: 7px;
  cursor: pointer;
  background-color: #fff;
  font-size: 1rem;
  color: #4baaf4;
  gap: 10px;
}

.action-button img {
  width: 15px;
  height: 15px;
}

.action-button:hover {
  opacity: 0.7;
}

.status-span {
  padding: 2px 10px;
  border-radius: 5px;
  display: inline-block;
}

.status-active {
  color: #17d000;
  background-color: #c5ffbe;
}

.status-frozen {
  color: #ff0000;
  background-color: #ffbebe;
}

.status-icon {
  margin-left: 1px;
  width: 7px;
  height: auto;
  position: relative;
  top: -2px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* el-table样式调整 start */

.el-table {
  --el-table-border-color: none;
  --el-table-border: 1px dashed #d4d4d4;
}

.el-button--small {
  border: none;
  color: #4baaf4;
}

::v-deep .el-tabs__item.is-active,
.el-tabs__item:hover {
  color: #fff;
  background-color: #000;
  border-radius: 17px 17px 0px 0px;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

::v-deep .el-tabs__active-bar {
  background: none;
}

::v-deep .el-tabs__item {
  font-size: 1rem;
}

.el-button--small {
  font-size: 0.9rem;
}

::v-deep .el-tabs__nav-wrap:after {
  background-color: #000;
}

/* el-table样式调整 end */
</style>
